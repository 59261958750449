@import url("https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100..1000;1,9..40,100..1000&display=swap");

$yellow: #ffe554;

body {
  font-family: "DM Sans";
  margin: 0;
  font-size: 16px;
  background-color: #f1f0ff;
  color: #191919;
}

h1 {
  font-size: 24px;
  margin-bottom: 42px;
  font-weight: 600;
}

.MuiCardContent-root {
  padding: 24px !important;
}

.MuiCard-root {
  border-radius: 18px !important;
}
