.StoryList {
  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-bottom: 26px;
    @media screen and (max-width: 760px) {
      flex-direction: column;
      align-items: baseline;
    }
    h1 {
      margin: 0;
      @media screen and (max-width: 760px) {
        margin-bottom: 16px;
      }
    }
  }
  &__filters {
    display: flex;
    justify-content: end;
    align-items: center;
    gap: 24px;
    @media screen and (max-width: 760px) {
      flex-direction: column;
      align-items: baseline;
    }
  }
  &__status {
    display: flex;
    border-radius: 10px;
    background-color: white;
    padding: 2px;
    &__item {
      flex: 1;
      border-radius: 10px;
      font-size: 12px;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 36px;
      padding: 0 32px;
      cursor: pointer;
      &.active {
        background: #6854ff;
        color: white;
      }
    }
  }
  &__select-restaurant {
    .MuiFormControl-root {
      margin-bottom: 0 !important;
    }
  }
  &__list {
    display: flex;
    gap: 48px;
    flex-wrap: wrap;
    margin-bottom: 32px;
    .MuiCardContent-root {
      padding: 4px !important;
    }
  }
  .MuiPagination-root {
    display: flex;
    justify-content: center;
  }
  &__item {
    cursor: pointer;
    position: relative;
    &__label {
      position: absolute;
      right: 24px;
      top: 24px;
      color: white;
      padding: 4px 12px;
      font-size: 12px;
      border-radius: 10px;
      &.validated {
        background-color: #65c466;
      }
      &.ai_validated {
        background-color: #54b5e2;
      }
      &.rejected {
        background-color: #ef4444;
      }
      &.created {
        background-color: #fbac13;
      }
    }
    &__image {
      width: 276px;
      height: 496px;
      border-radius: 15px;
      overflow: hidden;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    &__detail {
      position: absolute;
      left: 16px;
      right: 16px;
      bottom: 16px;
      background: white;
      border-radius: 10px;
      padding: 24px;
      display: flex;
      flex-direction: column;
      box-shadow: 0px 2px 24px rgba(0, 0, 0, 0.1);
      gap: 16px;
      &__label {
        color: rgba(25, 25, 25, 0.5);
        font-size: 12px;
        margin-bottom: 8px;
      }
      &__value {
        font-size: 14px;
        a {
          color: #6854ff;
          text-decoration: none;
        }
      }
    }
  }
}
