.PaymentInfoModal {
  color: #191919;
  &__steps {
    color: #6854ff;
    font-size: 16px;
    margin-bottom: 24px;
  }
  &__title {
    font-size: 24px;
    font-weight: 400;
    margin-bottom: 24px;
  }
  &__subtitle {
    margin-bottom: 24px;
    opacity: 0.6;
  }
}
