.Header {
  .MuiButtonBase-root {
    text-transform: none;
  }
  &__logo {
    max-width: 100px;
  }
  &__login {
    background-color: white !important;
    color: #6854ff !important;
  }
}
