.CompanyInfo {
  color: #191919;
  &__form {
    &__row {
      display: flex;
      gap: 12px;
      &__street-details {
        display: flex;
        gap: 12px;
      }
    }
  }
  button {
    width: 100%;
    height: 48px;
  }
}
