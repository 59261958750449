.PriceChart {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  box-sizing: border-box;
  margin-bottom: 24px;
  max-width: 1236px;
  @media screen and (max-width: 1000px) {
    flex-direction: column;
  }
  &__bar {
    width: 100%;
    height: 4px;
    margin-bottom: 20px;
  }
  &__tier {
    display: flex;
    flex-direction: column;
    padding: 24px 0 0 0;
    position: relative;
    border-left: 4px solid #6854ff;
    box-sizing: border-box;

    &:nth-child(1) {
      width: 198px;
      .PriceChart__bar {
        background-color: #ff5454;
      }
    }
    &:nth-child(2) {
      width: 212px;
      .PriceChart__bar {
        background-color: #fac900;
      }
    }
    &:nth-child(3) {
      width: 242px;
      .PriceChart__bar {
        background-color: #ff8d54;
      }
    }
    &:nth-child(4) {
      width: 292px;
      @media screen and (max-width: 1000px) {
        width: 268px;
      }
      .PriceChart__bar {
        background-color: #548dff;
      }
    }
    &:nth-child(5) {
      width: 292px;
      @media screen and (max-width: 1000px) {
        width: 274px;
      }
      .PriceChart__bar {
        background-color: #53c28d;
      }
    }
  }
  &__content {
    padding-left: 12px;
  }
  &__range {
    font-size: 14px;
  }
  &__price {
    font-size: 20px;
    color: #6854ff;
    font-weight: 400;
    margin-top: 10px;
    display: flex;
    gap: 4px;
    align-items: center;
    &__total {
      font-size: 14px;
    }
  }
  &__vat {
    font-size: 14px;
    color: #6854ff;
    margin-top: 5px;
  }
  &__extra {
    font-size: 12px;
    color: #6854ff;
  }
}
