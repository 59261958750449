.CampaignFile {
  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-bottom: 26px;
    h1 {
      margin-bottom: 16px;
    }
  }
  &__card {
    width: 100%;
    &__label {
      font-size: 12px;
      margin-bottom: 16px;
      display: flex;
      align-items: center;
      gap: 8px;
    }
  }
  &__form {
    &__label {
      color: #cccccc;
      margin-bottom: 12px;
      font-size: 12px;
      display: flex;
      align-items: center;
      gap: 8px;
    }
    &__inputs {
      display: flex;
      gap: 24px;
      flex-wrap: wrap;
      & > div {
        flex: 1;
      }
      .MuiTextField-root {
        min-width: 300px;
        min-width: 400px;
        margin-bottom: 0 !important;
        .MuiInputBase-root {
          background: #f1f0ff;
          border-radius: 10px;
          input,
          textarea {
            &::placeholder {
              color: #cccccc;
            }
          }
        }
      }
      .MuiOutlinedInput-notchedOutline {
        border: none;
      }
    }
    &__actions {
      display: flex;
      gap: 16px;
      justify-content: flex-end;
    }
  }
}
