.Dashboard {
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 38px;
    h1 {
      margin: 0;
    }
  }
  &__filters {
    display: flex;
    gap: 12px;
  }
  &__select-filter {
    .MuiFormControl-root {
      margin-bottom: 0 !important;
    }
  }
  &__cards {
    flex-wrap: wrap;
    display: flex;
    gap: 48px;
    margin-bottom: 48px;
    & > div {
      flex: 1;
      min-width: 280px;
    }
  }
  &__kpis {
    display: flex;
    gap: 48px;
    max-width: 395px;
    flex-direction: column;
    &__row {
      gap: 48px;
      display: flex;
    }
    @media screen and (max-width: 760px) {
      gap: 24px;
      &__row {
        gap: 24px;
      }
    }
  }
  &__kpi {
    background: white;
    border-radius: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
    flex: 1;
    position: relative;
    .MuiCardContent-root {
      padding: 32px !important;
      @media screen and (max-width: 760px) {
        padding: 24px 12px !important;
      }
    }
    &__tooltip {
      position: absolute;
      right: 8px;
      top: 8px;
    }
    &__value {
      color: #6854ff;
      font-weight: 600;
      font-size: 40px;
      margin-bottom: 8px;
    }
    &__label {
      font-size: 12px;
    }
  }
  &__active-stories {
    display: flex;
    align-items: baseline;
    gap: 8px;
    margin-bottom: 16px;
    font-size: 12px;
    .MuiTypography-gutterBottom {
      margin-bottom: 8px;
    }
    &__value {
      color: #6854ff;
    }
    &__content {
      display: flex;
      .MuiCardContent-root {
        display: flex;
        flex-direction: column;
        flex: 1;
      }
    }
    &__chart {
      flex: 1;
      align-items: center;
      display: flex;
      justify-content: center;
    }
  }
  &__validated {
    &__legend {
      display: flex;
      gap: 16px;
      margin-bottom: 24px;
      &__item {
        display: flex;
        gap: 8px;
        align-items: center;
        font-size: 12px;
        &__bullet {
          width: 16px;
          height: 16px;
          border-radius: 100%;
          &--validated {
            background-color: #dbf2f2;
          }
          &--rejected {
            background-color: #ffe0e6;
          }
        }
      }
    }
    &__chart {
      display: flex;
      flex: 1;
      justify-content: center;
      & > div {
        max-width: 220px;
      }
    }
  }
  &__stories {
    flex: 1;
    min-width: 300px;
    &__chart {
      max-height: 300px;
      overflow: hidden;
    }
  }
  &__total-stories {
    display: flex;
    gap: 16px;
    &__legend {
      width: 16px;
      height: 3px;
      border-top: 2px solid rgba(53, 162, 235, 1);
      &--dashed {
        border-top: 6px dotted rgba(53, 162, 235, 0.5);
        height: 1px;
      }
    }
    &__title {
      display: flex;
      align-items: baseline;
      gap: 8px;
      margin-bottom: 16px;
      font-size: 12px;
    }
    &__value {
      font-size: 12px;
      color: #35a2eb;
    }
  }
}
