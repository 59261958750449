.AuthModal {
  display: flex;
  justify-content: center;
  &__content {
    max-width: 700px;
    margin-top: 40px;
    padding: 20px;
    box-sizing: border-box;
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: space-between;
  }
  button {
    width: 100%;
    height: 50px;
  }
  .subtitle,
  .title {
    margin-bottom: 24px;
  }
  &__email {
    margin-top: 24px;
  }
  &__change-view {
    font-size: 14px;
    text-decoration: underline;
    margin-top: 16px;
    text-align: center;
  }
}
