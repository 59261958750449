.CampaignsEmptyState {
  &__box {
    background: white;
    max-width: 1280px;
    width: 100%;
    border-radius: 32px;
    display: flex;
    justify-content: space-between;
    overflow: hidden;
  }
  &__main {
    padding: 48px;
    @media screen and (max-width: 1000px) {
      flex: 1;
    }
  }
  &__steps {
    color: #6854ff;
    font-size: 16px;
    margin-bottom: 12px;
  }
  &__banner {
    @media screen and (max-width: 1000px) {
      display: none;
    }
    img {
      height: 100%;
    }
  }
  &__title {
    font-size: 40px;
    font-weight: 400;
    line-height: 100%;
    span {
      color: #6854ff;
    }
  }
  &__subtitle {
    margin: 24px 0;
    font-size: 16px;
    opacity: 0.6;
  }
  &__submit {
    width: 100%;
    height: 48px;
    &--campaigns {
      margin-top: 24px !important;
    }
  }
}
