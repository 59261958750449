.PaymentMethod {
  &__card {
    margin-top: 20px;
    margin-bottom: 20px;
    border: 1px solid;
    padding: 12px;
    border-radius: 12px;
  }
  &__error {
    color: red;
  }
  button {
    margin-top: 20px;
    width: 100%;
    height: 48px;
  }
}
